<div id="home">


  <app-recorder-worklet></app-recorder-worklet>

  <!-- <h3>home</h3>
  
  <p>
    Welcome to the home. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis sed beatae iure placeat voluptate aut, laboriosam repudiandae ea doloribus nihil!
  </p> -->

  <br>
  <app-recorder></app-recorder>
  <br>

  <div id="recs">
    <h4>previous recordings</h4>
    <app-player *ngFor="let recording of recordings" [recording]="recording"></app-player>
  </div>



  <br>

</div>