import { Component, OnInit, AfterViewInit, NgZone, ElementRef, ViewChild } from '@angular/core';

/*

Simple audio recorder using an Audio Worklet (since deprecaton of script processors) to 'post' audio arraybuffer to a Worker which passes
then on to a Lame MP3 encoder on the fly. 

Worklet: /assets/recorder-worlet/recorder-worklet.ts
> provides audio buffer from Microphone stream.
> gets sent back to this component which invokes a Worker (worker-realtime) which sends the buffers to a Lame encoder instance.
> On completion, the worker tells the Lame encoder to close up the file and sends it to the component as an MP3 audio Blob.
. This can save played, downloaded or whatever. (eg: IDBDatabase in-browser database)

*/


@Component({
  selector: 'app-recorder-worklet',
  templateUrl: './recorder-worklet.component.html',
  styleUrls: ['./recorder-worklet.component.css']
})
export class RecorderWorkletComponent implements OnInit, AfterViewInit {

  private stream: any;
  public has_stream: boolean = false;
  public is_recording: boolean = false;
  public is_monitoring: boolean = false;

  public is_testing: boolean = false;


  private context: AudioContext;
  private microphone: MediaStreamAudioSourceNode;
  private monitorNode: GainNode;
  private analyser: AnalyserNode;
  private recordingNode: AudioWorkletNode;
  private AudioContext: any;
  // private AudioContextPrototype: any; // = AudioContext.prototype;

  public canvasContext: CanvasRenderingContext2D;
  private canvasWidth: number;
  private canvasHeight: number;
  private canvasCurrentX: number = 0;

  private waveWidth: number;
  private waveHeight: number;
  private pixelScale: number;
  private realTimeWorker: Worker;

  public recordingLength: number = 0;

  @ViewChild('canvas') canvas: ElementRef;

  constructor(private ngZone: NgZone) {

    if (typeof window !== 'undefined' && window.AudioContext) {
      this.AudioContext = window.AudioContext
      // this.AudioContextPrototype = AudioContext.prototype;

    } else {
      console.log('Incompatible browser. No AudioContext');
    }


  }

  ngOnInit(): void {




  }


  ngAfterViewInit(): void {

    // if(typeof window !== 'undefined'){
    // this.ngZone.runOutsideAngular(() => {   
    //   this.pixelScale = window.devicePixelRatio;
    //   console.log('window.devicePixelRatio:', window.devicePixelRatio);
    //   console.log('_canvas:', this._canvas);
    //   this.waveWidth = this._canvas.nativeElement.width;// * 2.5;
    //   this.waveHeight = this._canvas.nativeElement.height;// * 2.5;
    //   console.log(this.waveWidth, this.waveHeight);
    //   console.log('waveHeight', this.waveHeight);
    //   this.waveContext = this._canvas.nativeElement.getContext('2d');
    //   this.waveContext.scale( window.devicePixelRatio,window.devicePixelRatio );
    //   this.waveContext.fillStyle = 'blue';
    //   //this.waveContext.fillRect(i * barWidth, height, 3, 3);
    //   // this.waveContext.fillRect(i * barWidth, 0, barWidth, height);
    //   this.waveContext.fillRect(0, 0, 50, 50);
    // });
    // }
  }

  async getMediaStream() {
    const constraints = { video: false, audio: { sampleSize: 16, channelCount: 1, sampleRate: 48000 } };
    try {
      // const supported = navigator.mediaDevices.getSupportedConstraints();
      // console.log('supported constraints:', supported);
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (this.stream) {
        this.has_stream = true;
      }
      console.log('got stream..', this.stream);
      return this.stream;
    } catch (error) {
      throw new Error(`
      MediaDevices.getUserMedia() threw an error. 
      Stream did not open.
      ${error.name} - 
      ${error.message}
    `);
    }
  }


  newAudioContext() {
    try {
      if (this.context) {
        this.context.close();
        this.context = null;
      }
      this.context = new this.AudioContext();

      console.log('newAudioContext: ', this.context);

    } catch (e) {
      console.log(e);
      setTimeout(function () {
        alert('Incompatible Browser?\n\nTry Chrome, Safari, Firefox or Edge');
      }, 500);
      return;
    }
  }

  async connectMic() {
    if (!this.context || !this.stream) {
      console.log('Unable to start mic.', this.context, this.stream);
      return;
    }

    // this.waveWidth = this.canvas.nativeElement.offsetWidth;
    // this.waveHeight = this.canvas.nativeElement.offsetHeight;
    this.canvasContext = this.canvas.nativeElement.getContext('2d');
    this.canvasHeight = this.canvas.nativeElement.height;
    this.canvasWidth = this.canvas.nativeElement.width;
    this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

    this.canvasContext.fillStyle = 'red';
    this.canvasContext.fillRect(0, 0, 1, 1);




    this.microphone = this.context.createMediaStreamSource(this.stream);
    // this.microphone.mediaStream = this.stream; // For Firefox? 
    console.log('Mic connected:', this.microphone);

    // For waveform drawing
    this.analyser = this.context.createAnalyser();
    this.analyser.smoothingTimeConstant = 0.3;
    this.analyser.fftSize = 1024;


    // this.realTimeWorker = new Worker('/assets/recorder-worklet/worker-realtime.js');
    // console.log(this.realTimeWorker);

    // const config = {
    //     sampleRate: 44100,
    //     bitRate: 128
    // };
    // console.log('INIT LAME: ', config);

    // this.realTimeWorker.postMessage({
    //     cmd: 'init',
    //     config: config
    // });

    // this.realTimeWorker.onmessage = (event) => {
    //   if (event.data.message === 'END_BUFFER') {
    //     // console.log('END BUFFER', event.data.buf);
    //     console.log('END_BUFFER received.. convert to mp3...');        
    //     // convert to mp3 blob..

    //     const _audio_blob = new Blob(event.data.buf, { type: 'audio/mp3' });

    //     console.log('_audio_blob', _audio_blob);

    //     // const reader = new FileReader();
    //     // reader.onload = (event) => {
    //     //   console.log('DATAURI:', event.target.result);
    //     //   const _url = event.target.result as string;
    //     //   window.open(_url, '_blank');
    //     // };
    //     // reader.readAsDataURL(_audio_blob);

    //     const _url = URL.createObjectURL(_audio_blob);
    //     // open in window like a lazy mofo...
    //     window.open(_url, '_blank');

    //   }
    // };


    const recordingProperties = {
      numberOfChannels: this.microphone.channelCount,
      sampleRate: this.context.sampleRate,
      //  maxFrameCount: this.context.sampleRate * 10, // would be ten seconds
    };

    console.log('props', recordingProperties);

    this.context.audioWorklet.addModule('/assets/recorder-worklet/recorder-worklet-encoder.js').then((ok) => {

      this.recordingNode = new AudioWorkletNode(
        this.context,
        'recorder-worklet',
        {
          processorOptions: recordingProperties,
        },
      );

      console.log('OK', this.recordingNode);

      this.monitorNode = this.context.createGain();
      // Leave audio volume at zero by default.
      this.monitorNode.gain.value = 0;
      this.is_monitoring = false;

      this.recordingNode.port.onmessage = (event) => {
        // console.log('PORT EVENT', event);

        if (event.data.message === 'AUDIO_RECORDING_COMPLETE') {
          console.log('RECORDING COMPLETE');
          // console.log('FINAL BUFFER:', event.data.buffer);
          this.saveBuffer(event.data.buffer);

        }

        if (event.data.message === 'UPDATE_VISUALISER') {
          // event.data.gain          
          this.visualiser(event);
        }

        if (event.data.message === 'UPDATE_RECORDING_LENGTH') {
          this.recordingLength = event.data.recordingLength;

          console.log('REC LENGTH:', Math.round(this.recordingLength / this.context.sampleRate * 100) / 100);

        }


      };


      // This will start firing the Worklet process() 
      this.microphone
        .connect(this.recordingNode)
        // .connect(this.analyser)
        .connect(this.monitorNode)
        .connect(this.context.destination);



    }).catch(err => {
      console.log('error loading worklet', err);
    })


    // this.recordingNode = await this.setupRecordingWorkletNode(recordingProperties);
    // console.log('Recorder Worklet Node connected:', this.recordingNode);



    // // TODO: add method to toggle mic monitor. But needs headphones or we get feedback.

  }

  start() {

    this.getMediaStream().then((stream: any) => {
      if (stream) {
        console.log('%cSTARTING', 'color:lime');
        this.newAudioContext();

        if (this.context) {
          this.connectMic();

          this.is_recording = true;

        }

      }
    });
  }

  stop() {

    this.recordingNode.port.postMessage({
      message: 'STOP'
    });

    if (this.microphone) {
      this.microphone.mediaStream.getTracks()[0].stop(); // This will fully close the device connection and stop the mic use indicator.   
      this.microphone.disconnect();
    }
    this.newAudioContext();

    console.log('stopped');
    this.is_recording = false;
  }

  toggleRecorder() {
    if (this.is_recording) {
      this.stop();
    } else {

      this.start();
    }
  }

  toggle() {
    // this.ngZone.runOutsideAngular(() => {
    this.toggleRecorder();
    // });
  }


  toggleMicTest() {

    this.is_testing = !this.is_testing;

    console.log('mic testing:', this.is_testing);


  }


  saveBuffer(buffer: any) {

   //  this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.canvasCurrentX = 0;

    console.log('SAVE BUFFER AS MP3 Blob');
    // convert to mp3 blob..
    const _audio_blob = new Blob(buffer, { type: 'audio/mp3' });
    console.log('_audio_blob', _audio_blob);

    // const reader = new FileReader();
    // reader.onload = (event) => {
    //   console.log('DATAURI:', event.target.result);
    //   const _url = event.target.result as string;
    //   window.open(_url, '_blank');
    // };
    // reader.readAsDataURL(_audio_blob);

    const _url = URL.createObjectURL(_audio_blob);
    // open in window for now
    window.open(_url, '_blank');

  }


  visualiser(event: any) {

    // console.log('VIZ', event.data.gain);

    this.draw(event.data.gain);

  }


  async setupRecordingWorkletNode(recordingProperties: any) {
    await this.context.audioWorklet.addModule('/assets/recorder-worklet/recorder-worklet.js');
    const WorkletRecordingNode = new AudioWorkletNode(
      this.context,
      'recorder-worklet',
      {
        processorOptions: recordingProperties,
      },
    );
    console.log('WorkletRecordingNode', WorkletRecordingNode);
    return WorkletRecordingNode;
  }


  draw(currentSampleGain) {
    const centerY = ((1 - currentSampleGain) * this.canvasHeight) / 2;

    const gainHeight = currentSampleGain * this.canvasHeight * 5;

    // Clear current Y-axis.
    this.canvasContext.clearRect(this.canvasCurrentX, 0, 1, this.canvasHeight);

    // Draw recording bar 1 ahead.
    this.canvasContext.fillStyle = 'red';
    this.canvasContext.fillRect(this.canvasCurrentX + 1, 0, 1, this.canvasHeight);

    // Draw current gain.
    this.canvasContext.fillStyle = 'black';
    this.canvasContext.fillRect(this.canvasCurrentX, centerY, 1, gainHeight);

    if (this.canvasCurrentX < this.canvasWidth - 2) {
      // Keep drawing new waveforms rightwards until canvas is full.
      this.canvasCurrentX++;
    } else {
      // If the waveform fills the canvas,
      // move it by one pixel to the left to make room.
      this.canvasContext.globalCompositeOperation = 'copy';
      this.canvasContext.drawImage(this.canvas.nativeElement, -1, 0);

      // Return to original state, where new visuals
      // are drawn without clearing the canvas.
      this.canvasContext.globalCompositeOperation = 'source-over';
    }
  }


}

