import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DatabaseService, RecordingItem } from '@services/database.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  public audio: HTMLAudioElement;

  public timeElapsed: BehaviorSubject<string> = new BehaviorSubject('00:00');
  public progress: BehaviorSubject<number> = new BehaviorSubject(0);
  public percentLoaded: BehaviorSubject<number> = new BehaviorSubject(0);
  public playerStatus: BehaviorSubject<string> = new BehaviorSubject('');
  public duration_secs: BehaviorSubject<number> = new BehaviorSubject(0);
  public position_secs: BehaviorSubject<number> = new BehaviorSubject(0);

  public currentRecording: RecordingItem;
  public currentTime: number = 0;
  public duration: number = 0;
  public status: string = "";
  public playing: boolean = false;
  public paused: boolean = false;
  public loading: boolean = false;

  public playlist: Array<RecordingItem> = [];
  public playlist_index: number = -1;


  constructor() {
    this.audio = new Audio();
    this.attachListeners();
    this.audio.volume = parseFloat(localStorage.getItem("vol")) || 0.6;
  }


  private attachListeners(): void {
    this.audio.addEventListener('timeupdate', this.calculateTime, false);
    this.audio.addEventListener('playing', this.setPlayerStatus, false);
    this.audio.addEventListener('pause', this.setPlayerStatus, false);
    this.audio.addEventListener('progress', this.calculatePercentLoaded, false);
    this.audio.addEventListener('waiting', this.setPlayerStatus, false);
    this.audio.addEventListener('ended', this.setPlayerStatus, false);
  }

  private calculateTime = (evt) => {
    if (isNaN(this.audio.duration)) {
      return;
    }
    console.log('timeupdate: ', evt);
    this.currentTime = this.audio.currentTime;
    this.duration = this.audio.duration;
    // update Observables
    this.duration_secs.next(this.duration);
    this.position_secs.next(this.currentTime);
    this.setTimeElapsed(this.currentTime);
    this.setProgress(this.duration, this.currentTime);

  }

  private calculatePercentLoaded = (evt) => {
    if (this.audio.duration > 0) {
      for (var i = 0; i < this.audio.buffered.length; i++) {
        if (this.audio.buffered.start(this.audio.buffered.length - 1 - i) < this.audio.currentTime) {
          let percent = (this.audio.buffered.end(this.audio.buffered.length - 1 - i) / this.audio.duration) * 100;
          console.log('percent loaded: ', percent);
          if (percent === 100) {
            console.log('audio is loaded');
          }
          this.setPercentLoaded(percent)
          break;
        }
      }
    }
  }

  playRecording(recording: RecordingItem) {
    console.log('playRecording: ', recording);
    this.currentRecording = recording;
    this.setAudioSource( URL.createObjectURL(recording.audio_blob) );
    this.playAudio();
  }

  public isLoading(): boolean { return this.loading; }
  public isPaused(): boolean { return this.audio.paused; }
  public isPlaying(): boolean { return !this.audio.paused; }

  public volumeSliderEvent(event) {
    this.audio.volume = event.value;
    localStorage.setItem("vol", event.value);
  }

  public volumeMute() {
    this.audio.volume = 0;
    localStorage.setItem("vol", "0");
  }

  public getVolume() {
    if (this.audio) {
      return this.audio.volume;
    }
  }
  private setPlayerStatus = (evt) => {
    switch (evt.type) {
      case 'playing':
        this.playing = true;
        this.paused = false;
        this.loading = false;
        this.status = 'playing';
        this.playerStatus.next(this.status);
        break;
      case 'pause':
        this.playing = false;
        this.paused = true;
        this.loading = false;
        this.status = 'paused';
        this.playerStatus.next(this.status);
        break;
      case 'waiting':
        this.playing = false;
        this.paused = false;
        this.loading = true;
        this.status = 'loading';
        this.playerStatus.next(this.status);
        break;
      case 'ended':
        this.playing = false;
        this.paused = false;
        this.loading = false;
        this.status = 'ended';
        this.playerStatus.next(this.status);
        this.currentTime = 0;
        break;
      default:
        this.status = 'paused';
        this.playerStatus.next(this.status);
        break;
    }
  }

  public getAudio(): HTMLAudioElement {
    return this.audio;
  }

  public setAudioSource(src: string): void {
    this.audio.src = src;
  }

  public playAudio(): void {
    if (!this.currentRecording) {
      return;
    }
    this.audio.play();
    if (localStorage.getItem("vol")) {
      this.audio.volume = parseFloat(localStorage.getItem("vol"));
    } else {
      this.audio.volume = 0.5;
    }
  }

  public pauseAudio(): void {
    this.audio.pause();
  }

  public resetPlayer(): void {
    if (!this.currentRecording) {
      return;
    }
    if (this.isPlaying()) {
      this.pauseAudio();
    }
    this.currentRecording = null;
    this.playlist = [];
    this.currentTime = 0;
    this.duration = null;


  }

  public seekAudio(position: number): void {
    this.audio.currentTime = position;

  }

  private setTimeElapsed(ct: number): void {
    let seconds = Math.floor(ct % 60),
      displaySecs = (seconds < 10) ? '0' + seconds : seconds,
      minutes = Math.floor((ct / 60) % 60),
      displayMins = (minutes < 10) ? '0' + minutes : minutes;
    this.timeElapsed.next(displayMins + ':' + displaySecs);
  }

  private setProgress(duration: number, currentTime: number): void {
    let progress_percent: number = ((100 / duration) * currentTime) || 0;
    // Register a tune play at (only) 20 seconds.
    // if(currentTime >= this.playback_ping_seconds && !this.seeked && !this.playback_ping){
    //     this.api.pingTunePlay( this.currentTune.id, Math.round(currentTime) ).subscribe(res => {
    //     //  console.log('ping response: ', res); // null
    //     });
    //     this.playback_ping = true;
    // }
    this.progress.next(progress_percent);
  }

  private setPercentLoaded(percent): void {
    this.percentLoaded.next(parseInt(percent, 10) || 0);
  }

  public getPercentLoaded(): Observable<number> {
    return this.percentLoaded.asObservable();
  }

  public getProgress(): Observable<number> {
    return this.progress.asObservable();
  }

  public getTimeElapsed(): Observable<string> {
    return this.timeElapsed.asObservable();
  }

  public getPlayerStatus(): Observable<string> {
    return this.playerStatus.asObservable();
  }


}
