import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { tap, map, catchError, switchMap } from 'rxjs/operators';

import { SafeUrl } from '@angular/platform-browser';
declare var log;

export interface RecordingItem {
  time?: number;
  title?: string;
  date?: Date;
  duration?: number;
  audio_blob: Blob;
  wave_blob: Blob;
  audio_blob_url?: string;
  wave_blob_url?: string;
  safe_wave_url?: SafeUrl;
}

export interface DataEventItem {
  type: string;
  recording?: RecordingItem;
  recordings?: RecordingItem[];
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  public dataEvent = new EventEmitter<DataEventItem>();

  private db: IDBDatabase;
  private request: IDBRequest;

  private recordings: RecordingItem[] = [];

  constructor() {


  }

  deleteRecording(recording: RecordingItem): Observable<boolean> {
    const response = new Subject<boolean>();
    let db, request = indexedDB.open('microdio-1', 1);
    let date = new Date();
    request.onsuccess = () => {
      db = request.result;
      log('DB: ready to delete', 'purple');
      var transaction = db.transaction(['recordings'], 'readwrite');
      transaction.oncomplete = (event) => {
        console.log('delete transaction complete: ', event);
        response.next(true);
        response.complete();
        this.dataEvent.emit({ type: 'delete', recording: recording });
      };
      transaction.onerror = (error) => {
        console.log('delete transaction error: ', error);
      };
      var objectStore = transaction.objectStore('recordings');
      var objectStoreRequest = objectStore.delete(recording.time);
      objectStoreRequest.onsuccess = (event) => {
        console.log('store request success: ', event);
      };
      objectStoreRequest.onerror = (error) => {
        console.log('store request error', error);
      };
    };
    return response.asObservable();
  }

  saveNewRecording(recording: RecordingItem) {
    let db, request = indexedDB.open('microdio-1', 1);
    let date = new Date();
    request.onsuccess = () => {
      db = request.result;
      log('DB: ready', 'cyan');
      var newItem: RecordingItem = { 
        time: date.getTime(), 
        title: 'rec_' + Math.round(date.getTime() / 1000), 
        date: date, 
        duration: recording.duration, 
        audio_blob: recording.audio_blob, 
        wave_blob: recording.wave_blob, 
        audio_blob_url: recording.audio_blob_url, 
        wave_blob_url: recording.wave_blob_url 
      };
      var transaction = db.transaction(['recordings'], 'readwrite');
      // report on the success of the transaction completing, when everything is done
      transaction.oncomplete = (event) => {
        console.log('db complete: ', event);
        this.dataEvent.emit({ type: 'new', recording: newItem });
      };
      transaction.onerror = (error) => {
        console.log('db error: ', error);
      };
      // create an object store on the transaction
      var objectStore = transaction.objectStore('recordings');
      // make a request to add our newItem object to the object store
      var objectStoreRequest = objectStore.add(newItem);

      objectStoreRequest.onsuccess = (event) => {
        console.log('store request success: ', event);

      };

      objectStoreRequest.onerror = (error) => {
        console.log('store request error', error);
      };
    };
  }

  getAllRecordings(): Observable<RecordingItem[]> {
    this.recordings = [];
    const response = new Subject<RecordingItem[]>();
    this.request = indexedDB.open('microdio-1', 1);
    this.request.onsuccess = () => {
      this.db = this.request.result;
      let transaction = this.db.transaction(['recordings'], "readonly");
      let objectStore = transaction.objectStore('recordings');
      objectStore.openCursor().onsuccess = (event) => {
        let cursor = event.target['result'];
        // console.log('openCursor: ', event, cursor);
        if (cursor) {
          //this.recordings.push(cursor.value);
          this.recordings.unshift(cursor.value);
          // console.log(cursor.value.time, cursor.value.title, cursor.value.audio_blob, cursor.value.wave_blob);
          response.next(this.recordings);
          cursor.continue();
        } else {
          // console.log('All entries collected');
          response.next(this.recordings);
          response.complete();
          return response.asObservable();
        }
      };
    };
    return response.asObservable();
  }

  initialiseDatabase() {
    let db, request = indexedDB.open('microdio-1', 1);
    request.onsuccess = () => {
      db = request.result;
      log('DB: ready', 'cyan');
    };
    request.onupgradeneeded = () => {
      db = request.result;
      if (!db.objectStoreNames.contains('recordings')) {
        log('DB: no recordings storage', 'orange');
        var recordingsOS = db.createObjectStore('recordings', { keyPath: 'time' });
        recordingsOS.createIndex('title', 'title', { unique: false });
        recordingsOS.createIndex('duration', 'duration', { unique: false });
        recordingsOS.createIndex('date', 'date', { unique: false });
        recordingsOS.createIndex('audio_blob', 'audio_blob', { unique: false });
        recordingsOS.createIndex('audio_blob_url', 'audio_blob_url', { unique: false });
        recordingsOS.createIndex('wave_blob', 'wave_blob', { unique: false });
        recordingsOS.createIndex('wave_blob_url', 'wave_blob_url', { unique: false });
      }
      if (!db.objectStoreNames.contains('logs')) {
        log('DB: no logs storage', 'orange');
        var logsOS = db.createObjectStore('logs', { keyPath: 'id', autoIncrement: true });
      }
      log('DB: upgrade done', 'cyan');
    };
    db = null;
  }


}
