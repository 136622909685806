import { Component, NgZone, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UtilsService } from '@services/utils.service';
import { DatabaseService, RecordingItem, DataEventItem } from '@services/database.service';
import { PlayerService } from '@services/player.service';

declare var log;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnChanges {
  
  ngOnChanges(changes:SimpleChanges): void {
    console.log('changes:', changes);
  }

  public recordings:RecordingItem[];


  constructor(private zone:NgZone, private utils: UtilsService, private dbs: DatabaseService, private player:PlayerService) {

    this.dbs.dataEvent.subscribe(data => {
      console.log('data event received: ', data);
      
      //console.log('recordings: ', this.recordings.length);

      if(data.type === 'new'){
        //console.log('new');
        this.recordings.unshift(data.recording);
      } else if(data.type === 'delete'){
        //console.log('del');
        let i = 0;
        this.recordings.forEach(rec => {
          if(rec.time === data.recording.time){
            this.recordings.splice(i, 1);
          }
          i++;
        });
      }

      //console.log('recordings: ', this.recordings.length);
      this.zone.run(() =>  this.recordings);  
      
      // this.player.playRecording(this.recordings[0]);

    });

  }

  ngOnInit() {

    this.dbs.getAllRecordings().subscribe(recordings => {
      // console.log('recordings:', recordings);
      this.recordings = recordings;
    },(error)=>{
      console.log('error getting records:', error)
    }, () => {
      console.log('got all recordings: ', this.recordings);
    });

    
  }

}
