import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DatabaseService, RecordingItem } from '@services/database.service';

declare var log;
declare var MediaRecorder: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public title = 'blipvox';

  // @ViewChild('canvas') _canvas: ElementRef;

  constructor(private dbs: DatabaseService) { }

  ngOnInit() {

    console.log('%cgreetings...  ', 'background: #222; padding:8px; color: #eee; font-family: Arial; font-style: italic; font-weight:bold; font-size:large');
    log('welcome to the blipvox demo', 'yellow');
    if (('AudioContext' in window)) {
      log('AudioContext support looks good');
    }
    if (('indexedDB' in window)) {
      log('IndexedDB support looks good');

      this.dbs.initialiseDatabase();
    }

    // Test MediaRecorder support.
    // (Though not using it, as it doesn't post predictable/defined size chunks to pass to Lame)

    var types = [
      "video/webm",
      "audio/webm",
      "video/webm\;codecs=vp8",
      "video/webm\;codecs=h264",
      "audio/webm\;codecs=opus",
      "audio/m4a",
      "audio/mp4",
      "video/mp4",
      "audio/ogg"
    ];

    console.log('MediaRecorder', MediaRecorder);

    for (var i in types) {
      console.log("Is " + types[i] + " supported? " + (MediaRecorder.isTypeSupported(types[i]) ? "Maybe!" : "Nope :("));
    }

  }


}
