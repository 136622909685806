import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '@components/home/home.component';


const routes: Routes = [
  { path:'', component:HomeComponent},
  // { path:'news', component:CategoryComponent, data: { category_slug:'news'} },
  // { path:'info', component:CategoryComponent, data: { category_slug:'info'} },
  // { path:'links', component:CategoryComponent, data: { category_slug:'links'} },
  // { path:'morons', component:CategoryComponent, data: { category_slug:'morons'} }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
