<div class="btn-test" (click)="toggleMicTest()">
    
</div>

<div class="recorder">

    <div class="btn-rec" (click)="toggle()"></div>

    <div class="canvas">
        <canvas #canvas></canvas>
    </div>

</div>
