<div id="cont">
  <div id="player">
    <!-- <div #player_ui class="player_ui" [style.backgroundImage]="getSafeWaveBackgroundUrl(recording)">
      title: {{ recording.title }}
    </div> -->
    <div #player_ui class="player_ui">
      <img [src]="recording.safe_wave_url">    
    </div>
    <div #btn_ctrl (click)="togglePlay()" class="btn_play">
      <div [class]="player.playing && recording == player.currentRecording ? 'icon-controller-paus' : 'icon-controller-play'"></div>
    </div>
  </div>
  <div (click)="deleteRecording()" class="icon-trash delete"></div>
  <h5><strong>{{ recording.duration | secsToTime }}</strong> {{ recording.title }}  <br>{{ recording.date }}  </h5>
  
</div>
