import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { DatabaseService, RecordingItem } from '@services/database.service';
import { PlayerService } from '@services/player.service';
// JS vars
declare var MP3Recorder;
declare var MediaRecorder;
declare var log;


@Component({
  selector: 'app-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.css']
})


export class RecorderComponent implements OnInit {

  @ViewChild('canvas_rec', { static: true }) canvas_rec: ElementRef;
  @ViewChild('btn_control', { static: true }) btn_control: ElementRef;

  public mic_tested: boolean = false;
  public mic_on: boolean;
  public is_recording: boolean;
  public recorder: any;
  public audio_context: AudioContext;
  public analyser: AnalyserNode;
  public microphone: MediaStreamAudioSourceNode;

  public waveContext: CanvasRenderingContext2D;
  private waveWidth: number;
  private waveHeight: number;
  private anim: any;
  private recordings: RecordingItem[];
  private current_AudioBuffer: any;
  private waveCol: string = 'white';

  private mediaRecorder: any = null;

  private realTimeWorker: Worker;

  private bff = new ArrayBuffer(0);

  constructor(private ngZone: NgZone, private dbs: DatabaseService, private player: PlayerService) {
    this.mic_tested = new Boolean(localStorage.getItem('mictest') || false).valueOf();

    this.realTimeWorker = new Worker('/assets/lame-recorder-worker/worker-realtime.js');

    console.log('realTimeWorker', this.realTimeWorker);

    this.init();

  }

  ngOnInit() {

    this.recorder = new MP3Recorder({
      bitRate: 128
    });


    // test
    // this.dbs.getAllRecordings().subscribe(recordings => {
    //   // console.log('recordings:', recordings);
    //   this.recordings = recordings;
    // },(error)=>{
    //   console.log('error getting records:', error)
    // }, () => {
    //   console.log('got all recordings: ', this.recordings);
    // });

  }

  init(){
   
      // console.log('INIT: ', config);
      let config:any = {
        sampleRate: 44100,
        bitRate: 128
      };
      // config.sampleRate = 44100; // context.sampleRate || 44100;
      // config.bitRate = config.bitRate || 128;
      this.realTimeWorker.postMessage({
        cmd: 'init',
        config: config
      });
   
      this.realTimeWorker.onmessage = function (e) {
        switch (e.data.cmd) {
          case 'end':
            console.log('realTimeWorker ended', e.data.buf);
            // if (mp3ReceiveSuccess) {
            //   mp3ReceiveSuccess(new Blob(e.data.buf, { type: 'audio/mp3' }));
            // }
            break;
          case 'error':
            console.log('realTimeWorker ERROR', e.data.error);
            //if (currentErrorCallback) {
            //  currentErrorCallback(e.data.error);
            //}
            break;
          default:
            console.log('error: unhandled cmd: ', e);
        }
      };
  }

  toggleRecording() {
    this.ngZone.runOutsideAngular(() => {
      this._toggleRecording();
      // this._toggleMediaRecorder();
    });
  }

  // public mediaRecorder:MediaRecorder;
  // public is_media_recorder_running: boolean = false;
  // async _toggleMediaRecorder() {
  //   console.log('toggleMediaRecorder');
  //   if (this.is_media_recorder_running && this.mediaRecorder) {
  //     this.mediaRecorder.stop();
  //     console.log('Recorder stopped');
  //     this.is_media_recorder_running = false;
  //     return;
  //   }
  //   let stream;
  //   const constraints = { video: false, audio: { sampleSize: 16, channelCount: 1, sampleRate: 48000 } };
  //   try {
  //     const supported = navigator.mediaDevices.getSupportedConstraints();
  //     console.log('supported constraints:', supported);
  //     stream = await navigator.mediaDevices.getUserMedia(constraints);
  //   } catch (error) {
  //     throw new Error(`
  //     MediaDevices.getUserMedia() threw an error. 
  //     Stream did not open.
  //     ${error.name} - 
  //     ${error.message}
  //   `);
  //   }
  //   this.mediaRecorder = new MediaRecorder(stream, { bitsPerSecond: 128000 });
  //   // this.mediaRecorder.addEventListener('dataavailable', ({ data }) => {
  //   //   console.log('DATA:', data);
  //   // });
  //   this.mediaRecorder.ondataavailable = ( { data } ) => {
  //     // console.log('DATA:', data);
  //     this.readAsArrayBuffer(data).then((data:ArrayBuffer) => {
  //       // Hopefully an ArrayBuffer to send to the lame worker
  //       // console.log(data);
  //       //  console.log(data.byteLength);
  //       // let sliced = new Float32Array(data.slice(0, 4096));
  //       // // sliced = new Float32Array([...sliced, data.slice(0, 4096)])
  //       // console.log('sliced', sliced);
  //       // this.realTimeWorker.postMessage({
  //       //   cmd: 'encode',
  //       //   buf: sliced
  //       // });
  //       // this.bff = this.concat(this.bff, data)
  //       // console.log('BFF', this.bff);
  //     }).catch((err) => {
  //       console.log('ERROR', err);
  //     });
  //   };
  //   this.mediaRecorder.start(1000);
  //   console.log('Recorder started');
  //   this.is_media_recorder_running = true;
  // }


  readAsArrayBuffer = (blob:Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (ev) => {
        reject(ev);
      };
    });
  }

  _toggleRecording() {

    console.log('toggle recording');
    if (this.mic_on) {
      this.recorder.stopMic();
      this.stopMicWave();
      this.mic_on = false;
    }

    if (!this.is_recording) {
      // CONNECT MIC AND START
      this.recorder.start((_context, _analyser, _microphone) => {
        log('start recording', 'red');
        console.log(_context);
        // controls_cont.style.display = 'none';

        //current_audio_mp3_blob = null;
        this.audio_context = _context;
        this.analyser = _analyser;
        this.microphone = _microphone;

        this.waveCol = '#bf4b4b'; // reddish
        this.startMicWave();
        //start timer ....
        var seconds = 0;
        this.is_recording = true;

      }, (error) => {
        console.log('mic error: ', error);
        alert('mic error!');
      });

    } else {
      // STOP!
      log('STOP recording', 'red');
      this.recorder.stop();
      this.stopMicWave();
      // get MP3
      //clearInterval(timer);
      this.recorder.getMp3Blob((blob) => {
        console.log('recorded blob: ', blob);
        this.saveRecording(blob);
      }, (error) => {
        // alert('error getting mp3 blob');
        console.log('getMp3Blob error: ', error);
      });
      this.is_recording = false;
      this.mic_on = false;
    }

  }

  blobToArrayBuffer(blob, callback) {
    const reader = new FileReader();
    reader.onprogress = (e) => {
      // console.log('blobToArrayBuffer progress : ', e);
    };
    reader.onload = (e) => {
      console.log('blobToArrayBuffer done : ', e);
      callback(reader.result);
    };
    reader.onerror = (e) => {
      console.log('blobToArrayBuffer error', e);
    };
    reader.readAsArrayBuffer(blob);
  }

  drawWaveform(audio_buffer, col, w, h, callback) {
    col = col || 'green';
    w || 1000;
    h || 1000;
    var canvas_wave = document.createElement('canvas');
    canvas_wave.width = w;
    //log('wave width: ' + w);
    canvas_wave.height = h;
    var wave_ctx = canvas_wave.getContext('2d');
    wave_ctx.fillStyle = col;
    var data = audio_buffer.getChannelData(0); // left only for now
    var step = Math.ceil(data.length / w);
    var amp = h / 2;
    for (var i = 0; i < w; i++) {
      var min = 1.0;
      var max = -1.0;
      for (var j = 0; j < step; j++) {
        var datum = data[(i * step) + j];
        if (datum < min)
          min = datum;
        if (datum > max)
          max = datum;
      }
      wave_ctx.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp));
    }
    // create a PNG from the canvas
    canvas_wave.toBlob(function (blob) {
      // console.log('rendered waveform image blob: ', blob);
      canvas_wave = null;
      wave_ctx = null;
      audio_buffer = null;
      callback(blob);
    }, 'image/png');
  }


  makeWaveformPNG(blob, callback) {
    log('make waveform PNG', '#ff9900');
    this.blobToArrayBuffer(blob, (audio_data) => {
      //console.log('audio data ArrayBuffer: ', audio_data);
      this.audio_context.decodeAudioData(audio_data, (audio_buffer) => {
        //console.log('audio data AudioBuffer: ', audio_buffer);
        this.current_AudioBuffer = audio_buffer;
        log('duration: ' + audio_buffer.duration);
        log('size: ' + audio_buffer.length);
        log('channels : ' + audio_buffer.numberOfChannels);
        log('sample rate: ' + audio_buffer.sampleRate);
        this.drawWaveform(audio_buffer, this.waveCol, 1000, this.waveHeight, (png_blob) => {
          callback(png_blob);
        });
        // Audio file ready... (file_blob or file_blob_data)
      });
    });
  }

  saveRecording(blob) {
    this.waveCol = 'white';
    // Make a PNG waveform of the audio data in the blob.
    this.makeWaveformPNG(blob, (wave_png_blob) => {
      log('PNG blob ok', '#ff9900');
      log('waveform blob type : ' + wave_png_blob.type, '#ff9900');
      log('waveform blob size : ' + wave_png_blob.size, '#ff9900');
      console.log('wave_png_blob: ', wave_png_blob);
      let date = new Date(),
        url = URL.createObjectURL(blob),
        wave_url = URL.createObjectURL(wave_png_blob);
      let newRecording: RecordingItem = {
        audio_blob: blob,
        wave_blob: wave_png_blob,
        audio_blob_url: url,
        wave_blob_url: wave_url
      }
      // get duration
      let _audio = new Audio();

      _audio.ondurationchange = (e) => {
        console.log('duration available: ', e, _audio.duration);
        newRecording.duration = _audio.duration;
        _audio = null;
        this.dbs.saveNewRecording(newRecording);
        log('ALL DONE!', 'cyan');
      };
      _audio.src = url;


    });
  }

  toggleMicTest() {
    console.log('tested: ', this.mic_tested);
    // if (this.mic_tested === false) {
    //   log('Mic has not been tested', 'red');
    //   return;
    // }
    if (!this.mic_on) {
      this.recorder.startMic((_context, _analyser, _microphone) => {
        log('start mic test', 'cyan');



        console.log(_context, _analyser, _microphone);
        this.mic_on = true;
        this.audio_context = _context;
        this.analyser = _analyser;
        this.microphone = _microphone;
        this.startMicWave();

      },
        (error) => {
          console.log('UH OH. NO MIC: ', error);
        });
    } else {
      log('stop mic');
      this.recorder.stopMic();
      this.mic_on = false;
      this.mic_tested = true;
      localStorage.setItem('mictest', 'true');
      this.stopMicWave();
    }
  }

  startMicWave() {
    log('startMicWave');


    // set up canvas etc.
    this.waveWidth = this.canvas_rec.nativeElement.offsetWidth;
    this.waveHeight = this.canvas_rec.nativeElement.offsetHeight;
    this.waveContext = this.canvas_rec.nativeElement.getContext('2d');
    console.log(this.waveWidth, this.waveHeight, this.waveContext);
    // start drawing.
    //this.draw();
    this.anim = this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => {
      this.draw();
    }));

  }

  draw() {
    // console.log('draw: ');
    // draw audio wave

    this.waveContext.clearRect(0, 0, this.waveWidth, this.waveHeight * 2.2);

    let y = new Uint8Array(this.analyser.frequencyBinCount); // analyser.fftSize / 2
    // let b, c, d, k, f;
    this.analyser.getByteTimeDomainData(y);

    // console.log(y);

    for (var i = 0; i < y.length; i++) {
      var value = y[i];
      var percent = value / 128;
      var height = this.waveHeight * percent;
      var offset = this.waveHeight - height;
      var barWidth = this.waveWidth / y.length;
      this.waveContext.fillStyle = this.waveCol;
      this.waveContext.fillRect(i * barWidth, height, 3, 3);

    }

    // loop
    this.anim = this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => {
      this.draw();
    }));

  }

  stopMicWave() {
    log('stopMicWave');
    cancelAnimationFrame(this.anim);
    this.waveContext.clearRect(0, 0, this.waveWidth, this.waveHeight * 2.2);

  }


  // hmmm
  concat (arrayBuffer1:ArrayBuffer, arrayBuffer2:ArrayBuffer) {
    var bytesPerIndex = 4,
      buffers = arrayBuffer1.slice.call(arrayBuffer2);
    
    console.log('bffz', buffers);

    // add self
    // buffers.unshift(this);
    
    buffers = buffers.map(function (item) {
      if (item instanceof Float32Array) {
        return item.buffer;
      } else if (item instanceof ArrayBuffer) {
        if (item.byteLength / bytesPerIndex % 1 !== 0) {
          throw new Error('One of the ArrayBuffers is not from a Float32Array');	
        }
        return item;
      } else {
        throw new Error('You can only concat Float32Array, or ArrayBuffers');
      }
    });
  
    var concatenatedByteLength = buffers
      .map(function (a) {return a.byteLength;})
      .reduce(function (a,b) {return a + b;}, 0);
  
    var concatenatedArray = new Float32Array(concatenatedByteLength / bytesPerIndex);
  
    var offset = 0;
    buffers.forEach(function (buffer, index) {
      concatenatedArray.set(new Float32Array(buffer), offset);
      offset += buffer.byteLength / bytesPerIndex;
    });
  
    return concatenatedArray;
  };

}
