import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RecordingItem, DatabaseService, DataEventItem } from '@services/database.service';
import { PlayerService } from '@services/player.service';



@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  inputs: ['recording'],
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  @ViewChild('player_ui', { static: true }) player_ui: ElementRef;
  @ViewChild('btn_ctrl', { static: true }) btn_ctrl: ElementRef;

  public duration_secs:number;
  public position_secs:number = 0;
  public progress:number = 0; // 0.0 - 1.0

  public recording:RecordingItem;
  public safeWaveUrl:SafeUrl;

  constructor(private ds:DomSanitizer, public player:PlayerService, private dbs:DatabaseService) {


  }

  getSafeWaveBackgroundUrl(recording:RecordingItem): SafeUrl {
    console.log('getsafe');
    let u = URL.createObjectURL(recording.wave_blob);
    return this.ds.bypassSecurityTrustStyle(`url('${URL.createObjectURL(recording.wave_blob)}')`);
  }


  togglePlay(){
    if(this.player.isPlaying() && this.recording === this.player.currentRecording ){
      this.player.pauseAudio();
      return;
    } else if(!this.player.isPlaying() && this.recording === this.player.currentRecording ){
      this.player.playAudio();
      return;
    }
    this.player.playRecording(this.recording);
  }

  deleteRecording(){
    console.log('delete key: ', this.recording);

    this.dbs.deleteRecording(this.recording).subscribe((data)=>{
      console.log(data);
    });

  }

  ngOnInit() {
    this.player.duration_secs.subscribe( duration => this.duration_secs = duration);
    this.player.position_secs.subscribe( position => this.position_secs = position);
    this.player.progress.subscribe( progress => this.progress = progress);
    this.recording.safe_wave_url = this.ds.bypassSecurityTrustUrl(URL.createObjectURL(this.recording.wave_blob));

  }

}
