import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  log(text, col = '#111'){
    console.log('%c>%c'+text+' ', 'background: #222; padding:4px; color:white; font-family: monospace; font-size:small', 'background: #222; padding:4px; color: '+ (col || '#5cd35f')+'; font-family: monospace; font-size:small');
  }

  formatSeconds(seconds){
    var date = new Date(1970,0,1);
    date.setSeconds(seconds);
    console.log(date.toTimeString());
    return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
  }

  // blobToArrayBuffer(blob, callback) {
  //   var reader = new FileReader();
  //   reader.addEventListener('progress', function (e) {
  //     //    console.log('blobToArrayBuffer progress : ', e);
  //   });

  //   reader.onload = (e:ProgressEvent<FileReader>) => {
  //     console.log('blobToArrayBuffer done : ', e.target.result);
  //     callback(e.target.result);
  //   };

  //   // reader.addEventListener('load', function (e) {
  //   //   //console.log('blobToArrayBuffer done : ', e.target.result);
  //   //   callback(e.target.result);
  //   // });
  //   reader.addEventListener('error', function (e) {
  //     console.log('blobToArrayBuffer error', e);
  //   });
  //   reader.readAsArrayBuffer(blob);
  // }

  // urlToDataBlob(data_url, callback){
  //   var xhr = new XMLHttpRequest();
  //   xhr.open('GET', data_url, true);
  //   xhr.responseType = 'blob';
  //   xhr.onload = function(e) {
  //     //console.log('urlToDataBlob: load: ', e);
  //     if (this.status == 200) {
  //       callback(this.response);
  //     }
  //   };
  //   xhr.send();
  // }

  // urlToDataArrayBuffer(data_url, callback){
  //   this.urlToDataBlob(data_url, function(blob){
  //     this.blobToArrayBuffer(blob, callback);
  //   });
  // }

}
