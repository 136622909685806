import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secsToTime'
})
export class SecsToTimePipe implements PipeTransform {

  /**
   * Transforms a number of seconds into a time in hours, minutes
   * and seconds.
   *
   * value: no. of seconds (e.g. 202.8 => 3:23)
   */ 
  transform(value: number): string {
    let min = Math.floor(value / 60)
    let sec = Math.round(value % 60)

    return min + (sec >= 10 ? ":" : ":0") + sec;
  }

}