<div>
  <div id="recorder">

    <div id="btn_cont">
      <div #btn_control class="_disabled" (click)="mic_tested ? toggleRecording() : toggleMicTest()" id="btn_control">
        <!-- {{ mic_tested ? (is_recording ? 'STOP':'REC') : ( mic_on?'OK':'test mic' ) }} -->
        <!-- <span class="icon-controller-record"></span> -->
        <span [class]="mic_tested ? (is_recording ? 'icon-controller-stop':'icon-controller-record') : ( mic_on ? 'icon-check':'icon-mic' )"></span>
      </div>
    </div>

    <div id="int_cont">
      <div id="interface">
        <canvas #canvas_rec id="canvas_rec"></canvas>
      </div>

    </div>



  </div>
</div>