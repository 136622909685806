import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { PlayerComponent } from './components/blipvox/player/player.component';
import { RecorderComponent } from './components/blipvox/recorder/recorder.component';

import { UtilsService } from '@services/utils.service';
import { SecsToTimePipe } from './services/secs-to-time.pipe';
import { RecorderWorkletComponent } from './components/blipvox/recorder-worklet/recorder-worklet.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    PlayerComponent,
    RecorderComponent,
    SecsToTimePipe,
    RecorderWorkletComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    UtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
